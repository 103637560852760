/* ==========================================================================
   FOOTER STYLES
========================================================================== */

.footer {
	@extend %cf;
	@include remit(margin-top, $g-spacing);
	background-color:$color-base;
	position:relative;
	overflow:hidden;

}


.footer {

	&__img {
		background-image: url('/assets/img/bg-footer.jpg');
// background-image: url('/assets/img/bg-home-1800.jpg');
// background-blend-mode: multiply;
// background-color:lighten($color-base, 50%);

		background-repeat:no-repeat;
		background-size:cover;
		background-position:top left;
		height:400px;


		@include mq($regular) {
			width:span(16);
			position:absolute;
			@include remit(padding-top, -$bsu*2);
			top:0;
			right:0;
			height:100%;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
		}


		// img {
		// 	mix-blend-mode: multiply;
		// 	clip: rect(10px, 20px, 30px, 40px);
		// }
	}

	&__content {
		@include remit(padding-top,$bsu*2);

		@include mq($regular) {
			width:span(12);
		}
	}

	&__logo {
		@include remit(margin-bottom, $bsu);
	}

	&__contact {
		@include remit(margin-bottom, $bsu*2);
		@include remit(padding-bottom, $bsu*2);
		border-bottom:1px solid rgba($white, 0.2);
	}

}