/* ==========================================================================
	HEADER BTTON STYLES
========================================================================== */
.header__btns {
	@extend %cf;
	@include span(10 last);
	text-align:right;
	@include remit(margin-top, 8);

	@include mq($nav-bp) {
		display:none;
	}
}

.header__btn {
	text-align:center;
	position:relative;
	z-index:900;
	// background-color:$color-base;
	display:inline-block;
	vertical-align:middle;
	@include remit(padding, 4);
	@include remit(margin-left, $hsu);
}