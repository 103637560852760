/* ==========================================================================
  BACKGROUND IMAGES
========================================================================== */

.bgImage {
	position: relative;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	display:block;
	@include remit(height, 400);

	@include mq($regular) {
		@include remit(height, 600);
	}

	@include mq($large) {
		background-attachment: fixed;
	}
}

.banner {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height:285px;
	@include remit(margin-bottom, $g-spacing/2);

	&:after {
		content:'';
		position:absolute;
		top:0;
		left:0;
		background-color:$white;
		// @include gradient-vertical($white, transparent, 20%, 70%);
		opacity:0.4;
		width:100%;
		height:100%;
		display:block;
	}

	// &--bottom {
	// 	background-position: bottom center;
	// }

	// &--top {
	// 	background-position: top center;
	// }
}



@each $page in $page-banners {
	.bgImage--#{$page} {
		background-image:url('/assets/img/banner-#{$page}-1800.jpg');
	}
}




// .banner--#{$service} {
// 	background-image:url('../img/banners/banner-#{$service}-600.jpg');

// 	@include mq($small) {
// 		background-image:url('../img/banners/banner-#{$service}-900.jpg');
// 	}

// 	@include mq($regular) {
// 		background-image:url('../img/banners/banner-#{$service}.jpg');
// 		background-size:cover;
// 	}
// }