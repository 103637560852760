/* ==========================================================================
	NAVIGATION STYLES
========================================================================== */

.nav {
	li {
		list-style:none;
		text-align:center;

		@include mq($nav-bp) {
			display:inline-block;
		}
	}

	a {
		display:block;
	}
}


/* ==========================================================================
	 MAIN NAV
========================================================================== */

.nav--main {

	ul {
		@include remit(margin-top, 80);

		@include mq($nav-bp) {
			display: flex;
			justify-content: space-between;
			text-align:right;
			margin-top:0;
		}
	}

	li {
		@include font-size(24, 36);

		@include mq($nav-bp) {
			@include font-size(18, no);
		}

		.header__nav.is-active & {
			@include fadeInDown($duration:0.5s);
			@extend %animate-nav-items;
		}
	}

	a {
		color:$white;
		position:relative;

		@include mq($nav-bp) {
			color:$color-text;
			@include remit(padding, 0 8 12);
		}

		&:before {
			transition:$g-trans;
			content:'';
			height:100%;
			position:absolute;
			top:-100%;
			left:0;
			right:0;
		}

		@include hover() {
			background-color:$color-sub;
			color:$white;

			@include mq($nav-bp) {
			&:before {
				transition:$g-trans;
				content:'';
				background-color:$color-sub;
				height:100%;
				position:absolute;
				top:-100%;
				left:0;
				right:0;
			}
		}
		}
	}

	.current {
		color:$color-base;

		@include mq($nav-bp) {
			background-color:$color-base;
			color:$white;
			position:relative;

			&:before {
				transition:$g-trans;
				content:'';
				background-color:$color-base;
				height:100%;
				position:absolute;
				top:-100%;
				left:0;
				right:0;
			}
		}
	}
}