/* ==========================================================================
	RIBBON STYLES
========================================================================== */

.ribbon {
	background-color:$lightgrey;
	position:absolute;
	top:0;
	left:0;
	transform:skewX(32deg);
	z-index:-1;
	opacity:0.5;

	width:200px;
	height:100vw;

	@include mq($medium) {
		width:20%;
		height:100%;
	}

	@include mq($regular) {
		width:30%;
		margin-left:30%;
	}

	@include mq($large) {
		width:20%;
		margin-left:10%;
	}

	&--right {
		margin-left:60%;

		@include mq($small) {
			margin-left:70%;
		}
	}
}

.ribbon--banner {
	z-index:200;
	height:50vh;
	// @include remit(width, 100);
	top:20%;
	opacity:0;

	@include mq($regular) {
		width:20%;
		margin-left:0;
	}

	@include mq($large) {
		width:20%;
		margin-left:10%;
	}

	&--02 {
		margin-left:30%;
		top:10%;
	}

	&--03 {
		margin-left:80%;
	}
}

.slick-active {
	.ribbon--banner {
		animation-name:ribbonBanner01;
		animation-fill-mode:both;
		animation-duration:10s;
		animation-delay:0;
	}

	.ribbon--banner--02 {
		animation-name:ribbonBanner02;
		animation-fill-mode:both;
		animation-duration:6s;
		animation-delay:1.5s;
	}

	.ribbon--banner--03 {
		animation-name:ribbonBanner03;
		animation-fill-mode:both;
		animation-duration:6s;
		animation-delay:0s;
	}
}


