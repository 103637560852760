/* ==========================================================================
  TESTIMONAL
========================================================================== */

.testimonial {
	border-top:1px solid $midgrey;
	border-bottom:1px solid $midgrey;
	@include remit(padding-top, 80);
	@include remit(padding-bottom, 80);
	margin-left:auto;
	margin-right:auto;
	color:$grey;
	@include font-size(21,yes, 21*1.7);
	padding-left:span(4);


	&:before {
		@include font-size(200);
		color: rgba($grey, 0.2);
		@include remit(margin-left, -10);

		@include mq($small) {
			margin-left:span(2);
		}

		@include mq($regular) {
			@include font-size(300);
			margin-left:span(2);
		}
	}

	@include mq($regular) {
		width:span(16);
		padding-right:span(2);
	}

}