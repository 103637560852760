/* ==========================================================================
	HEADER STYLES
========================================================================== */
.header {
	@extend %cf;
	// position:relative;
}

.header {
	&__topBar {
		background-color:rgba($white, 0.7);
		position:absolute;
		top:0;
		z-index:100;
		width:100%;
		@include remit(padding, $hsu 0);
	}

	&__logo {
		width:span(12);
		display:inline-block;

		@include mq($nav-bp) {
			width:span(6);
		}
	}

	&__nav {
		@extend %no-gutter;

		@include mq($nav-bp, max) {
			position:fixed;
			top:0;
			width:100%;
			height:100%;
			z-index:200;
			background-color:$color-base;
			color:$white;
		}

		@include mq($nav-bp) {
			@include span(isolate 16 at 8);
			display:inline-block;
			vertical-align:middle;
			text-align:right;
			@include remit(padding-top, 10);
		}

		@include mq($large) {
			@include span(isolate 14 at 10);
		}
	}
}

/* ==========================================================================
	HEADER NAV STYLES
========================================================================== */

.header__nav {
	display:none;

	@include mq($nav-bp) {
		display:block;
	}

	&.is-active {
		@include mq($nav-bp, max) {
			display:block;
			background-color:$color-sub;
			@include fadeInDown();
			width:100%;
		}
	}

}


