/* ==========================================================================
  SERVICE BOX IMAGES
========================================================================== */

.serviceBox {
	border-left:5px solid;
	@include remit(padding-left, $bsu);
	@include remit(padding-bottom, 6);
	@include remit(margin-bottom, $g-spacing/2);

	@include mq($regular) {
		@include span(isolate 10 at 2);

		&:nth-of-type(2n) {
			@include span(isolate 10 at 14);
		}
	}

	&__title {
		color:currentColor;
	}
}


.serviceBox--commercial {
	color:$commercial;
	border-color:$commercial;

	.btn {
		@include hover() {
			border-color:$commercial;
			color:$commercial;
		}
	}
}

.serviceBox--domestic {
	color:$domestic;
	border-color:$domestic;

	.btn {
		@include hover() {
			border-color:$domestic;
			color:$domestic;
		}
	}

}