/* ==========================================================================
	CONTACT STYLES
========================================================================== */

.tel {
	@include font-size(36);
}


.email {
	@include font-size(21);
}


.address {
	@include font-size(16);

	// .footer & {
	// 	color:$white;
	// }

	// &--float {
	// 	float:left;
	// 	margin-right:10%;

	// 	@include mq($x-large) {
	// 		margin-right:20%;
	// 	}

	// 	&:last-of-type{
	// 		margin-right:0;
	// 	}
	// }
}

