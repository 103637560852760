// /* ==========================================================================
// 	HEADER ANIMATIONS
// ========================================================================== */

$menu-items: 7;

%animate-nav-items {
	@for $i from 1 through $menu-items {
		&:nth-of-type(#{$i}n) {
			transform:translateY(($i*-200px));
			animation-delay:(0.3+($i*0.1s));;
		}
	}
}

$gallery-items: 6;
%animate-gallery-items {
	@for $i from 1 through $menu-items {
		&:nth-of-type(#{$i}n) {
			@include fadeInUp();
			animation-delay:(0.3+($i*0.1s));;
		}
	}
}

.slick-current {
	.slider__caption {
		@include slideInLeft($delay:0.5s);
	}

	.slider__captionText {
		@include fadeInUp($delay:1.5s);
	}
}

.slideInLeft 	{ @include slideInLeft(); }
.slideInRight 	{ @include slideInRight(); }
.slideInDown 	{ @include slideInDown(); }
// .slideInUp 		{ @include slideInUp(); }
.fadeIn 		{ @include fadeIn(); }
.fadeInUp 		{ @include fadeInUp(); }
.fadeInRight 	{ @include fadeInRight(); }
.fadeInLeft 	{ @include fadeInLeft(); }
.bounceInDown 	{ @include bounceInDown(); }

.animate--imageClip {
	animation-name:imageClip;
	animation-fill-mode:both;
	animation-duration:1s;
}

// /* ==========================================================================
// 	CUSTOM KEYFRAME ANIMATIONS
// ========================================================================== */

@keyframes imageClip {
	0% {
		opacity:0;
		clip-path: polygon(0 0, 0 0, 100% 100%, 100% 100%);
	}
	100% {
		opacity:1;
		clip-path: polygon(0 0, 65% 0, 100% 100%, 30% 100%);
	}
}


@include keyframes(ribbonBanner01) {
	0% {
		opacity: 0.7;
		transform:translateY(-2000px) translateX(-2000px) skewX(32deg);
	}
	100% {
		transform:translateY(800px) translateX(800px) skewX(32deg);
		opacity:0;
	}
}

@include keyframes(ribbonBanner02) {
	0% {
		opacity: 0.7;
		transform:translateY(2000px) translateX(-000px) skewX(32deg);
	}
	100% {
		transform:translateY(-800px) translateX(-800px) skewX(32deg);
		opacity:0;
	}
}

@include keyframes(ribbonBanner03) {
	0% {
		opacity: 0.7;
		transform:translateY(2000px) translateX(2000px) skewX(32deg);
	}
	100% {
		transform:translateY(-800px) translateX(-800px) skewX(32deg);
		opacity:0;
	}
}