/* ==========================================================================
	MODULES
========================================================================== */

.sectionSpacer {
	@include remit(padding-top, $g-spacing);
	@include remit(padding-bottom, $g-spacing);
}

/* ==========================================================================
	LAYOUT MODULES
========================================================================== */
.contentArea {
	position:relative;
	@include remit(margin-bottom, $bsu);

	@include mq($regular) {
		@include span(isolate 13 at 2);
	}

	@include mq($large) {
		@include span(isolate 11 at 2);
	}

	&__primary {
		@include mq($regular) {
			width:span(9 of 11);
		}
	}

	&__secondary {
		@include mq($regular) {
			@include span(14 last);
			text-align:right;
		}
	}


	&__img {
		@include mq($small) {
			clip-path: polygon(0 0, 65% 0, 100% 100%, 30% 100%);
		}

		// @include mq($regular) {
		// 	@include span(isolate 14 at 14);
		// }

		// @include mq($large) {
		// 	@include span(isolate 14 at 11);
		// }
	}
}