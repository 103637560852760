/* ==========================================================================
	INTRO STYLES
========================================================================== */

.intro {
@include remit(padding-top, $g-spacing/2);
@include remit(padding-bottom, $g-spacing/2);

	@include mq($regular) {
		@include span(isolate 13 at 2);
		@include remit(padding-top, $g-spacing);
		@include remit(padding-bottom, $g-spacing);
	}

	@include mq($large) {
		@include span(isolate 11 at 2);
	}



	// &__content {

	// 	@include mq($x-large) {
	// 		width:span(10 of 11);
	// 	}
	// }
}


/* ==========================================================================
	INTRO GALLERY STYLES
========================================================================== */

.introGallery {
	// border:1px solid red;

	@include mq($regular) {
		@include span(9 last);
	}

	@include mq($large) {
		@include remit(padding-top, $g-spacing/2);
	}
}

// .wow { visibility: hidden; }