/* ==========================================================================
	HEADING STYLES
========================================================================== */

h1, h2, h3, h4, h5, h6 {
	color:$color-text-header;
	font-family:$font-header;
	text-rendering: optimizeLegibility;
	font-weight:400;
}

h1, .alpha 		{
	@include font-size($h1-font-size);
}


h2, .beta {
	@include font-size($h2-font-size);
}

h3, .gamma	{
	@include font-size($h3-font-size);
}


h4, .delta {
	@include font-size($h4-font-size);
}

h5, .epsilon {
	@include font-size($h5-font-size);
}

h6, .zeta {
	@include font-size($h6-font-size);
}

.leadTitle {
	@include font-size(30, $g-spacing/2, 30*1.2);
	@include remit(padding-left, 40);

	@include mq($small) {
		@include font-size(40, $g-spacing/2, 40*1.2);
	}

	&__large {
		@include font-rem(60);
		display:block;
		@include remit(margin-left, -40);

		@include mq($small) {
			@include font-rem(74);
		}
	}
}


/* ==========================================================================
	PARAGRAPH AND LIST STYLES
========================================================================== */


/* List Style */
ul.square	{list-style: square inside;}
ul.disc 	{list-style: circle inside;}
ul.bullet 	{list-style: disc inside;}

// Remove vertical spacing from nested lists.
li {
	> ul,
	> ol{
		 margin-bottom:0;
	 }
}

.lead {
	color:$color-base;
	@include font-size($p-font-size * 1.125);
}


// This styles the main content of the page
// For other content sections and to change styling on paragraphs and headings and chunks create new blocks like side-bar etc
.content {
	p {
		color:$color-text;
		@include font-size($p-font-size, yes, $p-font-size*1.7);

		&:only-child {
			margin-bottom:0;
		}
	}

	ul, ol {
		@include remit(margin-bottom, $bsu);
		color:$color-text;
	}


	.bulletGroup {
		display:flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content:stretch;

		&--third li {width:32%;}
	}



	ul 	{list-style: none;}
	ol 	{list-style: decimal inside;}

	li	{
		@include font-size(15, no);
		@include remit(margin, 30);
		position:relative;

		&:before {
			content:'';
			border:1px solid lighten($grey, 20%);
			width:16px;
			height:16px;
			display:inline-block;
			position:absolute;
			@include remit(left, -$bsu);
			@include remit(top, 3);
		}
	}


}



.footer {

	p {
		@include font-size($footer-font-size);
		color:$white;
	}
}


// .bullet-cols {
// 	// number of cols, gap between in rems
// 	// TODO: improve this mixin to include PXs and base unit as PXs and modularise it
// 	@include cols(2, 1);
// }


/* ==========================================================================
   FONT WEIGHTS AND SIZES
========================================================================== */

%caps 				{text-transform:uppercase;}

// .extra-light 		{font-weight:100;}
// .light 				{font-weight:300;}
// .normal 			{font-weight:400;}
// .bold			 	{font-weight:700;}
// .extra-bold 		{font-weight:900;}


// .xsmall 			{@include font-size(11);}
.small 				{font-size:80% !important;}
// .medium 			{font-size:110% !important;}
.large 				{font-size:130% !important;}
// .xlarge 			{@include font-size(30);}