/* ==========================================================================
  BLOCKQUOTE
========================================================================== */

blockquote:before {
	content: "";
	padding:20px 0px 0px 0px;
	}

blockquote:after {
	content: "";
	float:right; margin-top:-20px;
}

blockquote {
	position:relative;
	z-index:10;
	font-style:italic;

	&:before {
		content: "\201C";

		/*Font*/
		font-style:normal;

		/*Positioning*/
		position: absolute;
		z-index:1;
		@include remit(top,-18);
		@include remit(left,-$hsu);

		@include mq($small) {
			@include remit(top,-$bsu);
			@include remit(left,-$bsu);
		}
	}

	cite {
		display: block;
		color:$color-sub;
		@include remit(padding-left, 20);
		@include remit(padding-top, 20);

		&:before {
			content: "\2014 \0020 ";
		}
	}
}

