@charset "UTF-8";
/*---------------------------------------------------
	Initial setup to import all our SASS based files.

	by Terry Upton (Amasci Creative Limited)
-----------------------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary, time {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background: transparent; }
  a:active, a:hover {
    outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 70%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

@keyframes shake {
  0%, 100% {
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateX(10px); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    transform: translateY(30px); }
  80% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px); }
  100% {
    transform: translateY(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px); }
  100% {
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px); }
  100% {
    transform: translateX(0); } }

.header__nav.is-active .nav--main li:nth-of-type(1n) {
  transform: translateY(-200px);
  animation-delay: 0.4s; }

.header__nav.is-active .nav--main li:nth-of-type(2n) {
  transform: translateY(-400px);
  animation-delay: 0.5s; }

.header__nav.is-active .nav--main li:nth-of-type(3n) {
  transform: translateY(-600px);
  animation-delay: 0.6s; }

.header__nav.is-active .nav--main li:nth-of-type(4n) {
  transform: translateY(-800px);
  animation-delay: 0.7s; }

.header__nav.is-active .nav--main li:nth-of-type(5n) {
  transform: translateY(-1000px);
  animation-delay: 0.8s; }

.header__nav.is-active .nav--main li:nth-of-type(6n) {
  transform: translateY(-1200px);
  animation-delay: 0.9s; }

.header__nav.is-active .nav--main li:nth-of-type(7n) {
  transform: translateY(-1400px);
  animation-delay: 1s; }

.gallery__item:nth-of-type(1n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 0.4s; }

.gallery__item:nth-of-type(2n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 0.5s; }

.gallery__item:nth-of-type(3n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 0.6s; }

.gallery__item:nth-of-type(4n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 0.7s; }

.gallery__item:nth-of-type(5n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 0.8s; }

.gallery__item:nth-of-type(6n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 0.9s; }

.gallery__item:nth-of-type(7n) {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  animation-delay: 1s; }

.slick-current .slider__caption {
  animation-name: slideInLeft;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.slick-current .slider__captionText {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.slideInLeft {
  animation-name: slideInLeft;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.slideInRight {
  animation-name: slideInRight;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.slideInDown {
  animation-name: slideInDown;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.fadeIn {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.fadeInUp {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.fadeInRight {
  animation-name: fadeInRight;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.bounceInDown {
  animation-name: bounceInDown;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

.animate--imageClip {
  animation-name: imageClip;
  animation-fill-mode: both;
  animation-duration: 1s; }

@keyframes imageClip {
  0% {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 100% 100%, 100% 100%); }
  100% {
    opacity: 1;
    clip-path: polygon(0 0, 65% 0, 100% 100%, 30% 100%); } }

@keyframes ribbonBanner01 {
  0% {
    opacity: 0.7;
    transform: translateY(-2000px) translateX(-2000px) skewX(32deg); }
  100% {
    transform: translateY(800px) translateX(800px) skewX(32deg);
    opacity: 0; } }

@keyframes ribbonBanner02 {
  0% {
    opacity: 0.7;
    transform: translateY(2000px) translateX(0px) skewX(32deg); }
  100% {
    transform: translateY(-800px) translateX(-800px) skewX(32deg);
    opacity: 0; } }

@keyframes ribbonBanner03 {
  0% {
    opacity: 0.7;
    transform: translateY(2000px) translateX(2000px) skewX(32deg); }
  100% {
    transform: translateY(-800px) translateX(-800px) skewX(32deg);
    opacity: 0; } }

/* ==========================================================================
 INIAL RESETS
========================================================================== */
/* See Normalize for addition resets and set-ups */
*, *:before, *:after {
  box-sizing: border-box; }

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
figure,
dl, dd, ol, ul,
form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0; }

/* ==========================================================================
	ONE WAY MARGINS
 ========================================================================== */
p, h1, h2, h3, h4, h5, h6, hgroup, dd, figure, pre, table, fieldset, legend, hr, time {
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

/* ==========================================================================
	MAIN FRAMEWORK DOCUMENT SETUP
 ========================================================================== */
html {
  font-size: 100%;
  overflow-y: scroll; }

body {
  margin: 0 auto;
  font-family: "Cardo", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #666;
  overflow: hidden; }

.container {
  max-width: 87.5em;
  margin: 0 auto;
  position: relative; }

/* ==========================================================================
  HR RULES
========================================================================== */
hr {
  border: solid #a3a3a3 0px;
  border-top: solid #a3a3a3 1px;
  height: 0px;
  clear: both; }

/* ==========================================================================
   SELECTION STYLING
========================================================================== */
/* This is used to change the highlight colour when selecting text.
 * Nice bespoke touch to websites.
 * Full article here: http://davidwalsh.name/css-selection
 */
::-moz-selection {
  background: #025493;
  color: #FFF;
  text-shadow: none; }

::selection {
  background: #025493;
  color: #FFF;
  text-shadow: none; }

/* ==========================================================================
   HELPER STYLES
========================================================================== */
.island-top {
  margin-top: 120px !important;
  margin-top: 7.5rem !important; }

.island-bottom {
  margin-bottom: 120px !important;
  margin-bottom: 7.5rem !important; }

.margin-top {
  margin-top: 24px !important;
  margin-top: 1.5rem !important; }

.margin-bottom {
  margin-bottom: 24px !important;
  margin-bottom: 1.5rem !important; }

.float-right {
  float: right !important; }

.float-left {
  float: left !important; }

.text-center {
  text-align: center; }

.container {
  padding: 0px 12px;
  padding: 0rem 0.75rem; }
  @media (min-width: 37.5em) {
    .container {
      padding: 0px 24px;
      padding: 0rem 1.5rem; } }

@media (max-width: 37.5em) {
  .header__nav, .no-gutter {
    margin-left: -12px;
    margin-left: -0.75rem;
    margin-right: -12px;
    margin-right: -0.75rem; } }

@media (min-width: 37.5em) and (max-width: 59.375em) {
  .header__nav, .no-gutter {
    margin-left: -24px;
    margin-left: -1.5rem;
    margin-right: -24px;
    margin-right: -1.5rem; } }

/* ==========================================================================
   CLEARFIX  STYLES
========================================================================== */
/*
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `%cf` class appearing over
 * and over in your markup.
 */
.container:after, .header:after, .header__btns:after, .footer:after, .term:after, .gallery:after, .gallery--full:after {
  content: "";
  display: table;
  clear: both; }

.clear {
  clear: both; }

.form .submit, .hamburger {
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

/* ==========================================================================
	HIDE STYLES
========================================================================== */
.hidden, #fax {
  display: none; }

.alt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .alt .focusable:active, .alt .focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

/* ==========================================================================
	HEADING STYLES
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  color: #025493;
  font-family: "Cardo", sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: 400; }

h1, .alpha {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.33333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h2, .beta {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h3, .gamma {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.14286;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h4, .delta {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.33333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h5, .epsilon {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

h6, .zeta {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.leadTitle {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.2;
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
  padding-left: 40px;
  padding-left: 2.5rem; }
  @media (min-width: 37.5em) {
    .leadTitle {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 1.2;
      margin-bottom: 60px;
      margin-bottom: 3.75rem; } }
  .leadTitle__large {
    font-size: 60px;
    font-size: 3.75rem;
    display: block;
    margin-left: -40px;
    margin-left: -2.5rem; }
    @media (min-width: 37.5em) {
      .leadTitle__large {
        font-size: 74px;
        font-size: 4.625rem; } }

/* ==========================================================================
	PARAGRAPH AND LIST STYLES
========================================================================== */
/* List Style */
ul.square {
  list-style: square inside; }

ul.disc {
  list-style: circle inside; }

ul.bullet {
  list-style: disc inside; }

li > ul,
li > ol {
  margin-bottom: 0; }

.lead {
  color: #025493;
  font-size: 20.25px;
  font-size: 1.26562rem;
  line-height: 1.18519;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.content p {
  color: #666;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.7;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }
  .content p:only-child {
    margin-bottom: 0; }

.content ul, .content ol {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #666; }

.content .bulletGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch; }
  .content .bulletGroup--third li {
    width: 32%; }

.content ul {
  list-style: none; }

.content ol {
  list-style: decimal inside; }

.content li {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 0;
  margin: 30px;
  margin: 1.875rem;
  position: relative; }
  .content li:before {
    content: '';
    border: 1px solid #d6d6d6;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    left: -24px;
    left: -1.5rem;
    top: 3px;
    top: 0.1875rem; }

.footer p {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.84615;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #FFF; }

/* ==========================================================================
   FONT WEIGHTS AND SIZES
========================================================================== */
.small {
  font-size: 80% !important; }

.large {
  font-size: 130% !important; }

/* ==========================================================================
	BASE LINK STYLES
========================================================================== */
a {
  transition: all 0.4s ease;
  color: #025493;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    color: #27a9e1;
    text-decoration: none; }

a.dotted {
  border-bottom: 1px dotted currentColor; }
  .footer a.dotted {
    opacity: 0.5; }

.footer a {
  color: #FFF;
  text-decoration: none; }
  .footer a:hover, .footer a:focus, .footer a:active {
    color: #FFF; }

/* ==========================================================================
	BUTTON STYLES
========================================================================== */
.btn {
  padding: 16px 24px;
  padding: 1rem 1.5rem;
  text-decoration: none;
  position: relative;
  text-align: center;
  display: inline-block;
  width: 140px;
  font-weight: 400 !important; }
  .btn:last-of-type {
    margin-bottom: 0; }
  .btn--outline {
    border: 1px solid; }
  .btn--midgrey {
    color: #a3a3a3;
    border-color: #d5d5d5; }
    .btn--midgrey:hover, .btn--midgrey:focus, .btn--midgrey:active {
      color: #27a9e1;
      border-color: #27a9e1; }
  .btn--large {
    font-size: 21px;
    font-size: 1.3125rem;
    width: auto;
    min-width: 200px; }

/*---------------------------------------------------
    COLOUR STYLES v1.1
  ---------------------------------------------------
    A set of common SASS colour styles by Terry Upton (Amasci Creative Limited)
	These should be unique for every project.
-----------------------------------------------------*/
/* ==========================================================================
   COLOUR STYLES
========================================================================== */
.color--base {
  color: #025493 !important; }

.color--sub {
  color: #27a9e1 !important; }

.required {
  color: #000; }

.color--commercial {
  color: #df6d45; }

.color--domestic {
  color: #d09c2d; }

.color-error {
  color: #b94a48 !important; }

/* ==========================================================================
	 IMAGES
========================================================================== */
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  font-style: italic;
  /*So that `alt` text is visually offset if images don’t load */ }

/**
 * Non-fluid images if you specify 'width' and/or 'height' attributes.
 */
img[width],
img[height] {
  max-width: none; }

svg {
  max-height: 100%; }

/* ==========================================================================
	 IMAGE PLACEMENTS
========================================================================== */
.img-right {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-left: 24px;
  margin-left: 1.5rem;
  float: right; }

.img-left {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-right: 24px;
  margin-right: 1.5rem;
  float: left; }

.img-center {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  display: block;
  margin-right: auto;
  margin-left: auto; }

/* ==========================================================================
	 MISC/UNIQUE IMAGE STYLES
========================================================================== */
.logo {
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

/* ==========================================================================
	ICON STYLES
========================================================================== */
.icon {
  display: inline-block;
  fill: currentColor;
  position: relative; }

.icon-error {
  width: 24px;
  height: 24px;
  top: 6px;
  top: 0.375rem;
  margin-right: 2px;
  margin-right: 0.125rem; }

/* ==========================================================================
  BLOCKQUOTE
========================================================================== */
blockquote:before {
  content: "";
  padding: 20px 0px 0px 0px; }

blockquote:after {
  content: "";
  float: right;
  margin-top: -20px; }

blockquote {
  position: relative;
  z-index: 10;
  font-style: italic; }
  blockquote:before {
    content: "\201C";
    /*Font*/
    font-style: normal;
    /*Positioning*/
    position: absolute;
    z-index: 1;
    top: -18px;
    top: -1.125rem;
    left: -12px;
    left: -0.75rem; }
    @media (min-width: 37.5em) {
      blockquote:before {
        top: -24px;
        top: -1.5rem;
        left: -24px;
        left: -1.5rem; } }
  blockquote cite {
    display: block;
    color: #27a9e1;
    padding-left: 20px;
    padding-left: 1.25rem;
    padding-top: 20px;
    padding-top: 1.25rem; }
    blockquote cite:before {
      content: "\2014 \0020 "; }

/* ==========================================================================
	MODULES
========================================================================== */
.sectionSpacer {
  padding-top: 120px;
  padding-top: 7.5rem;
  padding-bottom: 120px;
  padding-bottom: 7.5rem; }

/* ==========================================================================
	LAYOUT MODULES
========================================================================== */
.contentArea {
  position: relative;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }
  @media (min-width: 59.375em) {
    .contentArea {
      width: 52.85714%;
      float: left;
      margin-left: 4.28571%;
      margin-right: -100%; } }
  @media (min-width: 68.75em) {
    .contentArea {
      width: 44.28571%;
      float: left;
      margin-left: 4.28571%;
      margin-right: -100%; } }
  @media (min-width: 59.375em) {
    .contentArea__primary {
      width: 80.64516%; } }
  @media (min-width: 59.375em) {
    .contentArea__secondary {
      width: 57.14286%;
      float: right;
      margin-right: 0;
      text-align: right; } }
  @media (min-width: 37.5em) {
    .contentArea__img {
      clip-path: polygon(0 0, 65% 0, 100% 100%, 30% 100%); } }

/* ==========================================================================
	BASE FORM STYLES
========================================================================== */
label,
input,
textarea,
button,
select,
option {
  cursor: pointer; }

.form input:active,
.form select:active,
.form textarea:active,
.form input:focus,
.form select:focus,
.form textarea:focus,
textarea:active,
textarea:focus {
  cursor: text;
  outline: none; }

.form input,
.form select,
.form textarea {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 8px 6px;
  padding: 0.5rem 0.375rem;
  margin: 2px 0px 5px 0px;
  margin: 0.125rem 0rem 0.3125rem 0rem;
  color: #666;
  border: 1px solid #eeeeee;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none; }
  @media (false-max: 59.375em) {
    .form input:focus,
    .form select:focus,
    .form textarea:focus {
      font-size: 16px;
      font-size: 1rem; } }

label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #666; }

/* ==========================================================================
	ALL FORM STYLES
========================================================================== */
.form input,
.form select,
.form textarea {
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
  width: 90%; }
  .form input::-webkit-input-placeholder,
  .form select::-webkit-input-placeholder,
  .form textarea::-webkit-input-placeholder {
    color: rgba(179, 179, 179, 0.8); }
  .form input:-moz-placeholder,
  .form select:-moz-placeholder,
  .form textarea:-moz-placeholder {
    color: #b3b3b3; }
  .form input::-moz-placeholder,
  .form select::-moz-placeholder,
  .form textarea::-moz-placeholder {
    color: #b3b3b3; }
  .form input:-ms-input-placeholder,
  .form select:-ms-input-placeholder,
  .form textarea:-ms-input-placeholder {
    color: rgba(179, 179, 179, 0.8); }
  .form input:focus, .form input.required:focus,
  .form select:focus,
  .form select.required:focus,
  .form textarea:focus,
  .form textarea.required:focus {
    /* WebKit browsers */
    /* Mozilla Firefox 4 to 18 */
    /* Mozilla Firefox 19+ */
    /* Internet Explorer 10+ */
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: #025493;
    color: #FFF;
    outline: none; }
    .form input:focus::-webkit-input-placeholder, .form input.required:focus::-webkit-input-placeholder,
    .form select:focus::-webkit-input-placeholder,
    .form select.required:focus::-webkit-input-placeholder,
    .form textarea:focus::-webkit-input-placeholder,
    .form textarea.required:focus::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.8); }
    .form input:focus:-moz-placeholder, .form input.required:focus:-moz-placeholder,
    .form select:focus:-moz-placeholder,
    .form select.required:focus:-moz-placeholder,
    .form textarea:focus:-moz-placeholder,
    .form textarea.required:focus:-moz-placeholder {
      color: white; }
    .form input:focus::-moz-placeholder, .form input.required:focus::-moz-placeholder,
    .form select:focus::-moz-placeholder,
    .form select.required:focus::-moz-placeholder,
    .form textarea:focus::-moz-placeholder,
    .form textarea.required:focus::-moz-placeholder {
      color: white; }
    .form input:focus:-ms-input-placeholder, .form input.required:focus:-ms-input-placeholder,
    .form select:focus:-ms-input-placeholder,
    .form select.required:focus:-ms-input-placeholder,
    .form textarea:focus:-ms-input-placeholder,
    .form textarea.required:focus:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.8); }
  .form input.required,
  .form select.required,
  .form textarea.required {
    font-weight: 400; }
  .form input.error,
  .form select.error,
  .form textarea.error {
    border: 1px solid #b94a48;
    background-color: #f2dede;
    color: #b94a48;
    /* WebKit browsers */
    /* Mozilla Firefox 4 to 18 */
    /* Mozilla Firefox 19+ */
    /* Internet Explorer 10+ */ }
    .form input.error::-webkit-input-placeholder,
    .form select.error::-webkit-input-placeholder,
    .form textarea.error::-webkit-input-placeholder {
      color: rgba(185, 74, 72, 0.8); }
    .form input.error:-moz-placeholder,
    .form select.error:-moz-placeholder,
    .form textarea.error:-moz-placeholder {
      color: #b94a48; }
    .form input.error::-moz-placeholder,
    .form select.error::-moz-placeholder,
    .form textarea.error::-moz-placeholder {
      color: #b94a48; }
    .form input.error:-ms-input-placeholder,
    .form select.error:-ms-input-placeholder,
    .form textarea.error:-ms-input-placeholder {
      color: rgba(185, 74, 72, 0.8); }

.form .submit {
  transition: all 0.4s ease;
  background-color: #025493;
  color: #FFF;
  padding: 20px;
  padding: 1.25rem;
  min-width: 200px; }
  .form .submit:hover, .form .submit:focus, .form .submit:active {
    background-color: #27a9e1; }

.form__item {
  margin-top: 24px;
  margin-top: 1.5rem; }
  @media (min-width: 37.5em) {
    .form__item {
      width: 48.57143%;
      float: left;
      margin-right: 2.85714%;
      margin-top: 0; }
      .form__item:nth-of-type(2n) {
        margin-right: 0; } }

/* ==========================================================================
	ENQUIRY FORM STYLES
========================================================================== */
/* ==========================================================================
	ERROR STYLES
========================================================================== */
.error {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.84615;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin: 4px 0px 12px 0px;
  margin: 0.25rem 0rem 0.75rem 0rem;
  color: #b94a48;
  float: left;
  font-weight: bold;
  animation-name: shake;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden; }

/* ==========================================================================
	HEADER STYLES
========================================================================== */
.header__topBar {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 12px 0px;
  padding: 0.75rem 0rem; }

.header__logo {
  width: 48.57143%;
  display: inline-block; }
  @media (min-width: 53.75em) {
    .header__logo {
      width: 22.85714%; } }

@media (max-width: 53.75em) {
  .header__nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: #025493;
    color: #FFF; } }

@media (min-width: 53.75em) {
  .header__nav {
    width: 65.71429%;
    float: left;
    margin-left: 30%;
    margin-right: -100%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-top: 10px;
    padding-top: 0.625rem; } }

@media (min-width: 68.75em) {
  .header__nav {
    width: 57.14286%;
    float: left;
    margin-left: 38.57143%;
    margin-right: -100%; } }

/* ==========================================================================
	HEADER NAV STYLES
========================================================================== */
.header__nav {
  display: none; }
  @media (min-width: 53.75em) {
    .header__nav {
      display: block; } }
  @media (max-width: 53.75em) {
    .header__nav.is-active {
      display: block;
      background-color: #27a9e1;
      animation-name: fadeInDown;
      animation-iteration-count: 1s;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-timing-function: ease;
      animation-fill-mode: both;
      backface-visibility: hidden;
      width: 100%; } }

/* ==========================================================================
	HEADER BTTON STYLES
========================================================================== */
.header__btns {
  width: 40%;
  float: right;
  margin-right: 0;
  text-align: right;
  margin-top: 8px;
  margin-top: 0.5rem; }
  @media (min-width: 53.75em) {
    .header__btns {
      display: none; } }

.header__btn {
  text-align: center;
  position: relative;
  z-index: 900;
  display: inline-block;
  vertical-align: middle;
  padding: 4px;
  padding: 0.25rem;
  margin-left: 12px;
  margin-left: 0.75rem; }

/* ==========================================================================
   FOOTER STYLES
========================================================================== */
.footer {
  margin-top: 120px;
  margin-top: 7.5rem;
  background-color: #025493;
  position: relative;
  overflow: hidden; }

.footer__img {
  background-image: url("/assets/img/bg-footer.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  height: 400px; }
  @media (min-width: 59.375em) {
    .footer__img {
      width: 65.71429%;
      position: absolute;
      padding-top: -48px;
      padding-top: -3rem;
      top: 0;
      right: 0;
      height: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%); } }

.footer__content {
  padding-top: 48px;
  padding-top: 3rem; }
  @media (min-width: 59.375em) {
    .footer__content {
      width: 48.57143%; } }

.footer__logo {
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.footer__contact {
  margin-bottom: 48px;
  margin-bottom: 3rem;
  padding-bottom: 48px;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

/* ==========================================================================
	NAVIGATION STYLES
========================================================================== */
.nav li {
  list-style: none;
  text-align: center; }
  @media (min-width: 53.75em) {
    .nav li {
      display: inline-block; } }

.nav a {
  display: block; }

/* ==========================================================================
	 MAIN NAV
========================================================================== */
.nav--main ul {
  margin-top: 80px;
  margin-top: 5rem; }
  @media (min-width: 53.75em) {
    .nav--main ul {
      display: flex;
      justify-content: space-between;
      text-align: right;
      margin-top: 0; } }

.nav--main li {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 36px;
  margin-bottom: 2.25rem; }
  @media (min-width: 53.75em) {
    .nav--main li {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 1.33333;
      margin-bottom: 0; } }
  .header__nav.is-active .nav--main li {
    animation-name: fadeInDown;
    animation-iteration-count: 1s;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: ease;
    animation-fill-mode: both;
    backface-visibility: hidden; }

.nav--main a {
  color: #FFF;
  position: relative; }
  @media (min-width: 53.75em) {
    .nav--main a {
      color: #666;
      padding: 0px 8px 12px;
      padding: 0rem 0.5rem 0.75rem; } }
  .nav--main a:before {
    transition: all 0.4s ease;
    content: '';
    height: 100%;
    position: absolute;
    top: -100%;
    left: 0;
    right: 0; }
  .nav--main a:hover, .nav--main a:focus, .nav--main a:active {
    background-color: #27a9e1;
    color: #FFF; }
    @media (min-width: 53.75em) {
      .nav--main a:hover:before, .nav--main a:focus:before, .nav--main a:active:before {
        transition: all 0.4s ease;
        content: '';
        background-color: #27a9e1;
        height: 100%;
        position: absolute;
        top: -100%;
        left: 0;
        right: 0; } }

.nav--main .current {
  color: #025493; }
  @media (min-width: 53.75em) {
    .nav--main .current {
      background-color: #025493;
      color: #FFF;
      position: relative; }
      .nav--main .current:before {
        transition: all 0.4s ease;
        content: '';
        background-color: #025493;
        height: 100%;
        position: absolute;
        top: -100%;
        left: 0;
        right: 0; } }

/* ==========================================================================
	CONTACT STYLES
========================================================================== */
.tel {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.33333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.email {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.14286;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.address {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

/* ==========================================================================
	TERM STYLES
========================================================================== */
.term {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }
  .term:last-of-type {
    border: 0; }

/* ==========================================================================
	RIBBON STYLES
========================================================================== */
.ribbon {
  background-color: #eeeeee;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(32deg);
  z-index: -1;
  opacity: 0.5;
  width: 200px;
  height: 100vw; }
  @media (min-width: 47.5em) {
    .ribbon {
      width: 20%;
      height: 100%; } }
  @media (min-width: 59.375em) {
    .ribbon {
      width: 30%;
      margin-left: 30%; } }
  @media (min-width: 68.75em) {
    .ribbon {
      width: 20%;
      margin-left: 10%; } }
  .ribbon--right {
    margin-left: 60%; }
    @media (min-width: 37.5em) {
      .ribbon--right {
        margin-left: 70%; } }

.ribbon--banner {
  z-index: 200;
  height: 50vh;
  top: 20%;
  opacity: 0; }
  @media (min-width: 59.375em) {
    .ribbon--banner {
      width: 20%;
      margin-left: 0; } }
  @media (min-width: 68.75em) {
    .ribbon--banner {
      width: 20%;
      margin-left: 10%; } }
  .ribbon--banner--02 {
    margin-left: 30%;
    top: 10%; }
  .ribbon--banner--03 {
    margin-left: 80%; }

.slick-active .ribbon--banner {
  animation-name: ribbonBanner01;
  animation-fill-mode: both;
  animation-duration: 10s;
  animation-delay: 0; }

.slick-active .ribbon--banner--02 {
  animation-name: ribbonBanner02;
  animation-fill-mode: both;
  animation-duration: 6s;
  animation-delay: 1.5s; }

.slick-active .ribbon--banner--03 {
  animation-name: ribbonBanner03;
  animation-fill-mode: both;
  animation-duration: 6s;
  animation-delay: 0s; }

/* ==========================================================================
	INTRO STYLES
========================================================================== */
.intro {
  padding-top: 60px;
  padding-top: 3.75rem;
  padding-bottom: 60px;
  padding-bottom: 3.75rem; }
  @media (min-width: 59.375em) {
    .intro {
      width: 52.85714%;
      float: left;
      margin-left: 4.28571%;
      margin-right: -100%;
      padding-top: 120px;
      padding-top: 7.5rem;
      padding-bottom: 120px;
      padding-bottom: 7.5rem; } }
  @media (min-width: 68.75em) {
    .intro {
      width: 44.28571%;
      float: left;
      margin-left: 4.28571%;
      margin-right: -100%; } }

/* ==========================================================================
	INTRO GALLERY STYLES
========================================================================== */
@media (min-width: 59.375em) {
  .introGallery {
    width: 35.71429%;
    float: right;
    margin-right: 0; } }

@media (min-width: 68.75em) {
  .introGallery {
    padding-top: 60px;
    padding-top: 3.75rem; } }

/* ==========================================================================
	GALLELRY STYLES
========================================================================== */
.gallery__item {
  list-style: none;
  margin-bottom: 2.85714%;
  text-align: center;
  width: 31.42857%;
  float: left; }
  .gallery__item:nth-child(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0; }
  .gallery__item:nth-child(3n + 2) {
    margin-left: 34.28571%;
    margin-right: -100%;
    clear: none; }
  .gallery__item:nth-child(3n + 3) {
    margin-left: 68.57143%;
    margin-right: -100%;
    clear: none; }
  @media (min-width: 47.5em) {
    .gallery__item {
      width: 14.28571%;
      float: left; }
      .gallery__item:nth-child(6n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .gallery__item:nth-child(6n + 2) {
        margin-left: 17.14286%;
        margin-right: -100%;
        clear: none; }
      .gallery__item:nth-child(6n + 3) {
        margin-left: 34.28571%;
        margin-right: -100%;
        clear: none; }
      .gallery__item:nth-child(6n + 4) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none; }
      .gallery__item:nth-child(6n + 5) {
        margin-left: 68.57143%;
        margin-right: -100%;
        clear: none; }
      .gallery__item:nth-child(6n + 6) {
        margin-left: 85.71429%;
        margin-right: -100%;
        clear: none; } }
  @media (min-width: 59.375em) {
    .gallery__item {
      width: 48.57143%;
      float: left; }
      .gallery__item:nth-child(2n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .gallery__item:nth-child(2n + 2) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none; } }
  @media (min-width: 75em) {
    .gallery__item {
      width: 31.42857%;
      float: left; }
      .gallery__item:nth-child(3n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .gallery__item:nth-child(3n + 2) {
        margin-left: 34.28571%;
        margin-right: -100%;
        clear: none; }
      .gallery__item:nth-child(3n + 3) {
        margin-left: 68.57143%;
        margin-right: -100%;
        clear: none; } }

.gallery--full .gallery__item {
  list-style: none;
  margin-bottom: 2.85714%;
  text-align: center; }
  @media (min-width: 59.375em) {
    .gallery--full .gallery__item {
      width: 14.28571%;
      float: left; }
      .gallery--full .gallery__item:nth-child(6n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .gallery--full .gallery__item:nth-child(6n + 2) {
        margin-left: 17.14286%;
        margin-right: -100%;
        clear: none; }
      .gallery--full .gallery__item:nth-child(6n + 3) {
        margin-left: 34.28571%;
        margin-right: -100%;
        clear: none; }
      .gallery--full .gallery__item:nth-child(6n + 4) {
        margin-left: 51.42857%;
        margin-right: -100%;
        clear: none; }
      .gallery--full .gallery__item:nth-child(6n + 5) {
        margin-left: 68.57143%;
        margin-right: -100%;
        clear: none; }
      .gallery--full .gallery__item:nth-child(6n + 6) {
        margin-left: 85.71429%;
        margin-right: -100%;
        clear: none; } }

/* ==========================================================================
  TESTIMONAL
========================================================================== */
.testimonial {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  padding-top: 80px;
  padding-top: 5rem;
  padding-bottom: 80px;
  padding-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  color: #a3a3a3;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.7;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding-left: 14.28571%; }
  .testimonial:before {
    font-size: 200px;
    font-size: 12.5rem;
    line-height: 1.08;
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
    color: rgba(163, 163, 163, 0.2);
    margin-left: -10px;
    margin-left: -0.625rem; }
    @media (min-width: 37.5em) {
      .testimonial:before {
        margin-left: 5.71429%; } }
    @media (min-width: 59.375em) {
      .testimonial:before {
        font-size: 300px;
        font-size: 18.75rem;
        line-height: 1.04;
        margin-bottom: 24px;
        margin-bottom: 1.5rem;
        margin-left: 5.71429%; } }
  @media (min-width: 59.375em) {
    .testimonial {
      width: 65.71429%;
      padding-right: 5.71429%; } }

/* ==========================================================================
  BACKGROUND IMAGES
========================================================================== */
.bgImage {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 400px;
  height: 25rem; }
  @media (min-width: 59.375em) {
    .bgImage {
      height: 600px;
      height: 37.5rem; } }
  @media (min-width: 68.75em) {
    .bgImage {
      background-attachment: fixed; } }

.banner {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 285px;
  margin-bottom: 60px;
  margin-bottom: 3.75rem; }
  .banner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    display: block; }

.bgImage--home {
  background-image: url("/assets/img/banner-home-1800.jpg"); }

.bgImage--about {
  background-image: url("/assets/img/banner-about-1800.jpg"); }

.bgImage--domestic {
  background-image: url("/assets/img/banner-domestic-1800.jpg"); }

.bgImage--commercial {
  background-image: url("/assets/img/banner-commercial-1800.jpg"); }

.bgImage--gallery {
  background-image: url("/assets/img/banner-gallery-1800.jpg"); }

.bgImage--testimonials {
  background-image: url("/assets/img/banner-testimonials-1800.jpg"); }

.bgImage--contact {
  background-image: url("/assets/img/banner-contact-1800.jpg"); }

/* ==========================================================================
  SERVICE BOX IMAGES
========================================================================== */
.serviceBox {
  border-left: 5px solid;
  padding-left: 24px;
  padding-left: 1.5rem;
  padding-bottom: 6px;
  padding-bottom: 0.375rem;
  margin-bottom: 60px;
  margin-bottom: 3.75rem; }
  @media (min-width: 59.375em) {
    .serviceBox {
      width: 40%;
      float: left;
      margin-left: 4.28571%;
      margin-right: -100%; }
      .serviceBox:nth-of-type(2n) {
        width: 40%;
        float: left;
        margin-left: 55.71429%;
        margin-right: -100%; } }
  .serviceBox__title {
    color: currentColor; }

.serviceBox--commercial {
  color: #df6d45;
  border-color: #df6d45; }
  .serviceBox--commercial .btn:hover, .serviceBox--commercial .btn:focus, .serviceBox--commercial .btn:active {
    border-color: #df6d45;
    color: #df6d45; }

.serviceBox--domestic {
  color: #d09c2d;
  border-color: #d09c2d; }
  .serviceBox--domestic .btn:hover, .serviceBox--domestic .btn:focus, .serviceBox--domestic .btn:active {
    border-color: #d09c2d;
    color: #d09c2d; }

/* ==========================================================================
  SLIDER
========================================================================== */
.slider .slide {
  width: 100%;
  height: 100%; }

.slider__item {
  position: relative;
  overflow: hidden; }
  .slider__item img {
    width: 100%;
    height: 90vh;
    object-fit: cover; }

.slider__caption {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 15px;
  padding: 0.625rem 0.9375rem;
  position: absolute;
  left: 0;
  bottom: 5%;
  padding-left: 5%;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.44444;
  margin-bottom: 0; }

.slider__company {
  color: #27a9e1; }
  .slider__company:before {
    content: "\2014 \0020 "; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.9; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.8;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.8;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #FFF;
  word-wrap: break-word;
  padding-right: 36px;
  margin-top: 12px;
  margin-top: 0.75rem; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
		 * Remove all paddings around the image on small screen
		 */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear; }
  .hamburger:hover {
    opacity: 1; }

.hamburger-box {
  width: 32px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 32px;
    height: 4px;
    background-color: #025493;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
    .is-active .hamburger-inner, .is-active .hamburger-inner::before, .is-active .hamburger-inner::after {
      background-color: #FFF; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/* Slider */
.slick-dotted.slick-slider {
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    transition: all 0.4s ease;
    position: relative;
    display: inline-block;
    height: 24px;
    width: 24px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      transition: all 0.4s ease;
      border: 0;
      background: transparent;
      display: inline-block;
      height: 24px;
      width: 24px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      cursor: pointer;
      opacity: 0.25;
      color: #666;
      border: 1px solid currentColor; }
      @media (min-width: 59.375em) {
        .slick-dots li button {
          color: #a3a3a3; } }
      .slick-dots li button:hover, .slick-dots li button:focus {
        transition: all 0.4s ease;
        outline: none;
        opacity: 1; }
    .slick-dots li.slick-active button {
      background-color: #27a9e1;
      color: #27a9e1;
      opacity: 1; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%); }
  .main-menu, .footer-menu, [class*="btn"] {
    display: none; }
  .footer {
    border-top: 1px solid #a3a3a3; } }
