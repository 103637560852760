// Hamburger
// ==================================================
.hamburger {
	padding: $hamburger-padding-y $hamburger-padding-x;
	display: inline-block;
	cursor: pointer;

	@extend %btn-reset;

	transition-property: opacity, filter;
	transition-duration: $hamburger-hover-transition-duration;
	transition-timing-function: $hamburger-hover-transition-timing-function;

	&:hover {
		@if $hamburger-hover-use-filter == true {
			filter: $hamburger-hover-filter;
		}
		@else {
			opacity: $hamburger-hover-opacity;
		}
	}
}

.hamburger-box {
	width: $hamburger-layer-width;
	height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: $hamburger-layer-height / -2;

	&,
	&::before,
	&::after {
		width: $hamburger-layer-width;
		height: $hamburger-layer-height;
		background-color: $hamburger-layer-color;
		border-radius: $hamburger-layer-border-radius;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;

		.is-active & {
			background-color: $white;
		}
	}

	&::before,
	&::after {
		content: "";
		display: block;
	}

	&::before {
		top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}

	&::after {
		bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
}