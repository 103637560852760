/* ==========================================================================
  SLIDER
========================================================================== */
.slider .slide {width: 100%; height: 100%;}

.slider {

	&__item {
		position:relative;
		overflow:hidden;

		img {
			width:100%;
			height: 90vh;
			object-fit:cover;
		}
	}

	&__caption {
		background-color:rgba($white, 0.7);
		@include remit(padding, 10 15 );
		position:absolute;
		left:0;
		bottom:5%;
		padding-left:5%;
		@include font-size(18, no, 26);
	}

	&__company {
		color:$color-sub;
		&:before {
			content: "\2014 \0020 ";
		}
	}
}


