/* ==========================================================================
	GALLELRY STYLES
========================================================================== */

.gallery {
	@extend %cf;

	&__item {
		list-style:none;
		margin-bottom: gutter();
		text-align:center;
		@extend %animate-gallery-items;

		// img {
		// 	width:100%;
		// }

		@include gallery(8);

		@include mq($medium) {
			@include gallery(4);
		}

		@include mq($regular) {
			@include gallery(12);
		}

		@include mq(1200) {
			@include gallery(8);
		}
	}

}

.gallery--full {
	@extend %cf;

	.gallery__item {
		list-style:none;
		margin-bottom: gutter();
		text-align:center;
		@extend %animate-gallery-items;

		@include mq($regular) {
			@include gallery(4);
		}
	}

}