/* ==========================================================================
	BASE LINK STYLES
========================================================================== */

a {
	// Property, Duration, Animation Type
	transition: $g-trans;
	color:$color-link;
	text-decoration:none;

	@include hover() {
		color:$color-hover;
		text-decoration:none;
	}
}

a.dotted {
	border-bottom:1px dotted currentColor;

	.footer & {
		opacity:0.5;
	}
}

.footer a {
	color:$white;
	text-decoration:none;

	@include hover() {
		color:$white;
	}
}

// a[href^="tel:"].  {
// 	color:$color-sub;

// 	.footer & {
// 		color:$white;
// 	}
// }

/* ==========================================================================
	BUTTON STYLES
========================================================================== */

%btn, .btn {
	@include remit(padding, 16 24);
	text-decoration:none;
	position:relative;
	text-align:center;
	display:inline-block;
	width:140px;
	font-weight:400 !important;

	&:last-of-type {
		margin-bottom:0;
	}

	&--outline {
		border:1px solid;
	}

	&--midgrey {
		color:$grey;
		border-color:$midgrey;

		@include hover() {
			color:$color-hover;
			border-color:$color-hover;
		}
	}

	&--large {
		@include font-rem(21);
		width:auto;
		min-width:200px;
	}

	// & + & {
	// 	@include mq($small) {
	// 		@include remit(margin-left, $bsu);
	// 	}
	// }
}

// .btn-arrow {
// 	&:before {
// 		position:absolute;
// 		left:10px;
// 		top:38%;
// 		width: 0;
// 		height: 0;
// 		border-left: 7px solid $white;
// 		border-bottom: 6px solid transparent;
// 		border-top: 6px solid transparent;
// 		content:'';
// 	}
// }



// /* ==========================================================================
//   MISC LINK STYLES
// ========================================================================== */

// .top {
// 	@extend .icon-arrow-up;
// 	@include font-size(24);
// 	@include remit(padding, $hsu);
// 	@include remit(right, $bsu);
// 	@include remit(bottom, $bsu);
// 	cursor:pointer;
// 	position:fixed;
// 	background-color:$color-sub;
// 	color:$white;
// 	display: none;

// 	&:hover,&:focus,&:active {
// 		background-color:$color-hover;
// 	}
// }